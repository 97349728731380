.h-60{
    height: 60%;
}
.h-40{
    height: 40%;
}
.h-70{
    height: 70%;
}
.h-30{
    height: 30%;
}
.message-from-arrow, .message-to-arrow{
    display: inline-block;
    width: 18px;
    height: 18px;
    position: relative;
    left: 10px;
    top: 20px;
    vertical-align: top;
    border: 1px solid black;
    border-right: 0px;
    border-top: 0px;
    background-color: #ebecf7;
    transform: rotate(45deg);
}
.message-to-arrow{
    left: -10px;
    border: 1px solid black;
    border-left: 0px;
    border-bottom: 0px;
    background-color: #f9edd7;
}
.message{
    display: inline-block;
    background: #f9edd7;
    border-radius: 3px;
    padding: 0 10px 10px 10px;
    margin-top: 16px;
    max-width: 90%;
}
.message > .datetime-stamp{
    margin-top: -16px;
    display: block;
    font-size: 11px;
    font-weight: bold;
    color: rgb(119, 119, 119);
}

/*
.message-from > .datetime-stamp{
    margin-left: -10px;
}
.message-to> .datetime-stamp{
    margin-left: 10px;
}*/

.message-from{
    background-color: #eef8ff;
    border-radius: 20px 20px 20px 0;
}
.message-to{
    text-align: left;
    background: #f5fbea;
    border-radius: 20px 20px 0 20px;
}
.sender-block{
    color: #6c757d;
    font-size: 12px;
    margin-top: 5px;
}
.message-from > .sender-block, .message-from > .datetime-stamp{
    margin-left: 15px;
}
.message-to > .sender-block, .message-to > .datetime-stamp{
    padding-right: 15px;
}

.messages-background{
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: white;
    display: grid;
    grid-template-columns: 100%;
    align-items: flex-end;
    background-size: 45%;
    background-repeat: no-repeat;
    background-position: center 50px;
    font-size: 12px;
}

.transitioning .messages-background,
.transitioning .thread-form textarea:not(:focus) {
    transition: height .2s ease;
}

.message-block-subject{
    background-color: #cecece;
    padding-bottom: 2px;
}

.custom-disabled{
    color:gray;
    cursor: wait;
    pointer-events: none
}

.cursor-wait{
    cursor: wait;
}

.menu-button{
    padding-right: 5px;
}
.disableTemplate  {
    background-color: lightgray;
    border: 1px solid grey;
    cursor: context-menu;
}
.disableTemplateLink  {
    cursor: context-menu;
    pointer-events: none;
}

.two-manage-buttons > button {
    width: 49.6%;
}
.two-manage-buttons > button:last-child {
    float: right;
}