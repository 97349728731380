@import "~bootstrap/scss/bootstrap.scss";
@import "~font-awesome/scss/font-awesome.scss";
@import "../js/components/ticket/tickets.css";

.double-buttons-group{
  @extend .row;
}

.double-buttons-group > div:first-child {
  @extend .col-6;
  padding-right: 2px;
}
.double-buttons-group > div:last-child {
  @extend .col-6;
  padding-left: 2px;
}
.double-buttons-group .btn
{
  @extend .col-12;
}

.dropdown-menu{
  min-width: 5rem;
}
#main-nav-menu {
  z-index: 1100;
}
.nav-fixed{
  padding-left: 100px;
  margin-top: 0px !important;
}

main {
  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
  height: -webkit-fill-available;
  max-height: 100vh;
  overflow-x: auto;
  overflow-y: hidden;
}

.main-container > .backplate {
  position: relative;
  flex-grow: 1;
  background-color: #cecece;
  border-radius: 5px;
  height: 100vh;
  padding: 4px;
}

.b-example-divider {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.bi {
  vertical-align: -.125em;
  pointer-events: none;
  fill: currentColor;
}

.dropdown-toggle { outline: 0; }

.nav-flush .nav-link {
  border-radius: 0;
}

.btn-toggle {
  display: inline-flex;
  align-items: center;
  padding: .25rem .5rem;
  font-weight: 600;
  color: rgba(0, 0, 0, .65);
  background-color: transparent;
  border: 0;
}
.btn-toggle:hover,
.btn-toggle:focus {
  color: rgba(0, 0, 0, .85);
  background-color: #d2f4ea;
}

.btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform .35s ease;
  transform-origin: .5em 50%;
}

.btn-toggle[aria-expanded="true"] {
  color: rgba(0, 0, 0, .85);
}
.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}

.btn-toggle-nav a {
  display: inline-flex;
  padding: .1875rem .5rem;
  margin-top: .125rem;
  margin-left: 1.25rem;
  text-decoration: none;
}
.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
  background-color: #d2f4ea;
}

.scrollarea {
  overflow-y: auto;
}

.fw-semibold { font-weight: 600; }
.lh-tight { line-height: 1.25; }

.popper {
  max-width: var(--popper-max-wight-custom , 30vw ) !important;
  white-space: normal !important;
  word-break: var( --popper-word-break-custom , break-all) !important;
}

.transfer-popper-active {
  z-index: 1050 !important;
}

.hint-popper {
  word-wrap: break-word;
}

.light {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 1em;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

.clipboard-popover {
  z-index: 10000;
}
.clipboard-popover > .popover-header {
  background-color: white;
  padding: 1em;
  box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  border: none;
}

.relation-table {
  overflow-x: auto;
}

.relation-table:not(:last-child) {
  margin-bottom: 1em;
}

.relation-table th {
  text-align: center;
}

thead.sticky, .group-contact {
  position: sticky;
  top: 0;
}

thead.sticky {
  box-shadow: -1px 1px 0px #dde2e6;
  background-color: white;
  z-index: 20;
}

thead.sticky > tr, thead.sticky > tr > th {
  border: none;
  box-shadow: inset -1px 1px #dde2e6;
}

thead.sticky > tr:last-child {
  box-shadow: 0px 1.5px #717171;
}

.group-contact {
  font-weight: bold;
}

.message-left{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.contextFromButton{
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1030;
}

.nobr {
  white-space: nowrap;
}

.loading {
  opacity: .6;
  cursor: progress;
}

.ticket-menu .btn {
  border-radius: 0;
}

.fixed-editable {
  z-index: 1050;
}

.event-block {
  display: flex;
  font-size: 1em;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  background-color: rgba(13, 202, 240, 0.25) !important;
}

.event-block .event-date, .event-block .right-offset {
  width: 22%;
}

.event-block .event-date {
  color: #6c757d !important;
  margin-left: 0.5rem !important;
}

.event-block .event-message {
  width: 76%;
  text-align: center !important;
  word-break: break-all;
}

.diadoc-event {
  background-color: #EFFBF4 !important;
  border-radius: 20px;
  margin-top: 17px;
}

.block-event {
  background-color: #EFFBF4 !important;
  border-radius: 20px;
  margin-top: 17px;
}


table.ticket-table tr th{
  @extend .text-center;
  vertical-align: middle;
}
table.ticket-table tr td:nth-child(1), table.ticket-table tr th:nth-child(1){
  width: 8rem;
  padding: 0px 2px;
}

table.ticket-table tr td:nth-child(3), table.ticket-table tr th:nth-child(3){
  width: 11rem;
}

.modal-body {
  font-size: var(--bs-body-font-size);
}
.modal-md {
  --bs-modal-width: 540px;
}

.v-contextmenu {
  display: none;
}
.v-contextmenu:last-child {
  display: block;
}

.drag-handle {
  display: flex;
  align-items: center;
  margin-left: -5px;
  margin-right: 5px;
  cursor: move;
  opacity: .3;
}

.sortable-chosen .drag-handle {
  opacity: 1;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.drag-ghost {
  opacity: 0.5;
}

:root {
  --ourCompanyColor: #5582ff;
  --clientCompanyColor: #00d700;
}

.border-blue {
  border-color: var(--clientCompanyColor)!important;
}
.border-green {
  border-color: var(--ourCompanyColor)!important;
}

.input-disabled {
  background-color: var(--bs-secondary-bg) !important;
  opacity: 1;
}
.input-disabled:focus {
  box-shadow: none !important;
  border-color: var(--bs-border-color) !important;
}